/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Navigation from "./navigation";
import { toKebabCase } from "../helpers";

import style from "../styles/post.module.css";
import { getPostAside } from '../helpers/index'

function renderSeparator() {
  return <span className={style.tag}>-</span>
}

const Post = ({
  meta,
  html,
  previousPost,
  nextPost,
  readingTime,

}) => {
  const previousPath = previousPost && previousPost.frontmatter.path;
  const previousLabel = previousPost && previousPost.frontmatter.title;
  const nextPath = nextPost && nextPost.frontmatter.path;
  const nextLabel = nextPost && nextPost.frontmatter.title;
  return (
    <div className={style.post}>
      <div className={style.postContent}>
        <h1 className={style.title}>{meta.title}</h1>
        <div className={style.meta}>
          <span className={style.date}>{meta.date}</span>
          {meta.tags ? (
            <span className={style.tags}>
              {renderSeparator()}
              {meta.tags.map(tag => (
                <Link
                  to={`/tag/${toKebabCase(tag)}/`}
                  key={toKebabCase(tag)}
                >
                  <span className={style.tag}>#{tag}</span>
                </Link>
              ))}
            </span>
          ) : null}
          {readingTime ? (
            <>
              {renderSeparator()}
              <span className={style.tag}>{readingTime}</span>
            </>
          ) : null}
          {meta.type === 'link' ? (
            <>
              {renderSeparator()}
              <a href={meta.link}>external link</a>
            </> 
          ) : null}
        </div>

        {meta.coverImage && (
          <Img
            fluid={meta.coverImage.childImageSharp.fluid}
            className={style.coverImage}
          />
        )}

        <span className={style.postBody}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          {/* <Navigation
                previousPath={previousPath}
                previousLabel={previousLabel}
                nextPath={nextPath}
                nextLabel={nextLabel} 
              />*/}
        </span>
        </div>
    </div>
  );
};

Post.propTypes = {
  meta: PropTypes.object,
  html: PropTypes.string,
  previousPost: PropTypes.object,
  nextPost: PropTypes.object,
  readingTime: PropTypes.string,
};

export default Post;
